// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-layouts-chapter-page-index-js": () => import("./../src/components/layouts/ChapterPage/index.js" /* webpackChunkName: "component---src-components-layouts-chapter-page-index-js" */),
  "component---src-components-layouts-course-page-index-js": () => import("./../src/components/layouts/CoursePage/index.js" /* webpackChunkName: "component---src-components-layouts-course-page-index-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-courses-js": () => import("./../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

